import React from 'react'
import { Menu } from 'semantic-ui-react'

import { ImpersonableUser } from 'src/types'

export interface SellerAccountInformationHeaderProps {
  companyName?: string
  fullName?: string
  impersonableUsers?: ImpersonableUser[]
  isComputer: boolean
}

const SellerAccountInformationHeader = ({
  companyName,
  fullName,
  impersonableUsers = [],
  isComputer,
}: SellerAccountInformationHeaderProps) => (
  <Menu.Item header data-e2e="seller-account-information-header">
    <div data-e2e="account-information-user-name" className="account-information-user-name">
      {fullName}
    </div>
    {(impersonableUsers.length === 0 || isComputer) && (
      <div data-e2e="account-information-company-name" className="account-information-company-name">
        {companyName}
      </div>
    )}
  </Menu.Item>
)

export default SellerAccountInformationHeader
