import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Divider, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import { useBreakpoints } from '@procsea/design-system'

import CompanyDisplay from 'src/components/presentationals/navigation/account/CompanyDisplay'
import LanguageMenuItem from 'src/components/presentationals/navigation/account/LanguageMenuItem'
import LogoutMenuItem from 'src/components/presentationals/navigation/account/LogoutMenuItem'
import StopImpersonationMenuItem from 'src/components/presentationals/navigation/account/StopImpersonationMenuItem'
import TermsOfUseMenuItem from 'src/components/presentationals/navigation/account/TermsOfUseMenuItem'
import { APPLI_URL } from 'src/constants/constants'
import { useSeller } from 'src/contexts/userContext'
import { useOptionFlags } from 'src/hooks'
import { LocaleParams, UserType } from 'src/types/router'

import './SellerAccountInformation.css'
import SellerAccountInformationHeader from './SellerAccountInformationHeader'

export interface SellerAccountInformationProps {
  analyticsFirstTab?: string
  onClose?: () => void
}

const ScrollableContainer = styled('div')<{ isMobile: boolean }>`
  overflow-y: auto;
  height: ${props => (props.isMobile ? '300px' : 'auto')};
`

const SellerAccountInformation = ({
  analyticsFirstTab,
  onClose,
}: SellerAccountInformationProps) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const { locale } = useParams<LocaleParams>()
  const { optionFlagCanUseMapping, optionFlagCanUseManagement } = useOptionFlags()
  const seller = useSeller()

  return (
    <div data-e2e="account-information-content" className="account-information-content">
      <Menu secondary size="large" vertical className="account-information-links">
        <SellerAccountInformationHeader
          companyName={seller?.companyName}
          fullName={seller?.fullName}
          impersonableUsers={seller?.impersonableUsers}
          isComputer={isDesktop}
        />

        {(isMobile || isTablet) && (
          <CompanyDisplay
            companyName={seller?.companyName}
            companyId={seller?.companyId}
            impersonableUsers={seller?.impersonableUsers}
          />
        )}

        <Divider />

        <ScrollableContainer isMobile={isMobile}>
          {isMobile && (
            <Menu.Item
              as={Link}
              data-e2e="invoices-menu-item"
              onClick={onClose}
              to={`/${locale}${APPLI_URL.SELLER.INVOICING.INVOICES}`}
            >
              {gettext('Invoices')}
            </Menu.Item>
          )}

          {isMobile && analyticsFirstTab && (
            <Menu.Item
              as={Link}
              to={`/${locale}${APPLI_URL.SELLER.ANALYTICS}${analyticsFirstTab}/`}
              onClick={onClose}
            >
              {gettext('Analytics')}
            </Menu.Item>
          )}

          <Menu.Item
            as={Link}
            to={`/${locale}/${UserType.SELLER}/my-company/`}
            onClick={onClose}
            data-e2e="my-company-menu-item"
          >
            {gettext('My company')}
          </Menu.Item>

          <Menu.Item
            as={Link}
            to={`/${locale}/${UserType.SELLER}/customer-categories/`}
            onClick={onClose}
            data-e2e="customer-categories-menu-item"
          >
            {gettext('Customer categories')}
          </Menu.Item>

          <Menu.Item
            as={Link}
            to={`/${locale}${APPLI_URL.SELLER.CUSTOMERS.BASE}`}
            onClick={onClose}
            data-e2e="my-customers-menu-item"
          >
            {gettext('Customers')}
          </Menu.Item>

          {isMobile && optionFlagCanUseMapping && (
            <Menu.Item
              as={Link}
              to={`/${locale}${APPLI_URL.SELLER.MAPPING.BASE}`}
              onClick={onClose}
              data-e2e="mapping-menu-item"
            >
              {gettext('Mapping')}
            </Menu.Item>
          )}

          {isMobile && optionFlagCanUseManagement && (
            <Menu.Item
              as={Link}
              to={`/${locale}${APPLI_URL.SELLER.MANAGEMENT.BASE}`}
              onClick={onClose}
              data-e2e="management-menu-item"
            >
              {gettext('Management')}
            </Menu.Item>
          )}

          <TermsOfUseMenuItem locale={locale} />

          <LogoutMenuItem />

          {seller?.isImpersonate && <StopImpersonationMenuItem />}
        </ScrollableContainer>

        <Divider />

        <LanguageMenuItem />
      </Menu>
    </div>
  )
}

export default SellerAccountInformation
