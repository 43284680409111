import { format, getUnixTime, setDefaultOptions } from 'date-fns'
import { useEffect } from 'react'

import { registerLocale, setDefaultLocale } from '@procsea/design-system'

import { useSeller, useUser } from 'src/contexts/userContext'
import { useCompanyVerticals, useLocale, useSegment, useUserCounts } from 'src/hooks'
import { useMarketplacesQuery } from 'src/queries/shared'
import { IdentifySellerArgs, UserType } from 'src/types'
import { dateLocales } from 'src/utils'
import * as hotjarService from 'src/utils/tracking/hotjar/hotjar.service'

const useInitializeSellerDashboard = () => {
  const locale = useLocale()
  const seller = useSeller()
  const user = useUser()
  const { identify, isEnabled } = useSegment()
  const { data: ecosystems = [] } = useMarketplacesQuery()
  const verticals = useCompanyVerticals()
  const { todayOrdersToPrepareByVerticalCount, futureOrdersToPrepareByVerticalCount } =
    useUserCounts()

  const isReady = !!seller && verticals.length > 0

  useEffect(() => {
    registerLocale(locale, dateLocales[locale])
    setDefaultLocale(locale)
    setDefaultOptions({ locale: dateLocales[locale] })
  }, [])

  useEffect(() => {
    if (user && seller && ecosystems.length > 0 && locale && isEnabled) {
      if (user.isImpersonate) {
        hotjarService.tagImpersonatedSeller()
      }
      const now = new Date()

      identify<IdentifySellerArgs>(`${seller.userId}`, {
        actorType: UserType.SELLER,
        allEcosystemIds: ecosystems.map(({ id }) => id).join(' - '),
        allEcosystemNames: ecosystems.map(({ name }) => name).join(' - '),
        companyCatalogDataIsValidated: seller.companyCatalogDataIsValidated,
        companyLegalDataIsValidated: seller.companyLegalDataIsValidated,
        companyName: seller.companyName,
        country: seller.country,
        createdAt: user.created,
        currencyId: seller.currencyId,
        ecosystemIds: ecosystems.map(({ id }) => id),
        ecosystemNames: ecosystems.map(({ name }) => name),
        email: user.email,
        firstName: user.firstName,
        futureOrdersToPrepare: futureOrdersToPrepareByVerticalCount ?? 0,
        impersonableUsers: user.impersonableUsers,
        impersonatorIsStaff: user.impersonatorIsStaff,
        isDemo: seller.companyIsDemo,
        isImpersonate: user.isImpersonate,
        labelIds: seller.labelIds,
        language: locale,
        lastName: user.lastName,
        lastSeen: getUnixTime(now),
        lastSeenDay: format(now, 'eeee'),
        lastSeenHour: format(now, 'H'),
        lastSeenMinute: format(now, 'm'),
        todayOrdersToPrepare: todayOrdersToPrepareByVerticalCount ?? 0,
      })
    }
  }, [ecosystems, seller, user, isEnabled, locale])

  return { isReady }
}

export default useInitializeSellerDashboard
