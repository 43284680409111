import { compact } from 'lodash'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useBreakpoints } from '@procsea/design-system'

import SideBar, { UserInfo } from 'src/components/base/design-system/SideBar/SideBar'
import { NavItem } from 'src/components/base/design-system/SideBar/SideNavItem/SideNavItem'
import TabBar from 'src/components/base/design-system/TabBar/TabBar'
import HelpMenu from 'src/components/presentationals/navigation/HelpMenu'
import SellerAccountMenuItem from 'src/components/presentationals/seller-dashboard/navigation/menu-items/account/SellerAccountMenuItem'
import { AppEnvironment } from 'src/components/presentationals/shared/navigation/'
import { APPLI_URL } from 'src/constants/constants'
import { useSellerSellerCompanyId, useUser } from 'src/contexts/userContext'
import { useOptionFlags, useUserCounts } from 'src/hooks'
import klarysLogoWhiteSmall from 'src/images/klarys-logo-white-small.svg'
import { usePaginatedDashboardsQuery } from 'src/queries/seller'
import { useMarketplacesQuery, useSellerSubscriptionsQuery } from 'src/queries/shared'
import { LocaleParams, SellerUser } from 'src/types'

import { useUnreadChatMessagesCount } from '../../chat/store/useChatStore'
import MainMenu from '../../navigation/MainMenu/MainMenu'
import { Logo } from './logo/Logo'
import SellerMobileHeader from './mobile-header/SellerMobileHeader'

const StyledSideBar = styled(SideBar)`
  position: sticky;
  height: 100vh;
  flex: 0 0 auto;
  left: 0;
  top: 0;
  z-index: 14;
  overflow-y: auto;
  overflow-x: hidden;
`

interface Counters {
  totalPendingClaimsCount: number
  totalTodayOrdersToPrepareCount: number
  totalUnsyncOrdersCount?: number
  unmappedPurchasedProductsCount?: number
  unreadChatMessagesCount: number
  waitingInvoicesCount?: number
}

interface OptionFlags {
  canUseChat?: boolean
  canUseInvoices?: boolean
  canUseManagement?: boolean
  canUseMapping?: boolean
  canUsePurchaseOrders?: boolean
  canUseSellPage?: boolean
  canUseStockManagementModule?: boolean
}

export interface GetSellerNavItemsArgs {
  analyticsFirstTab?: string
  counters: Counters
  isMobile: boolean
  locale: string
  optionFlags: OptionFlags
  pathname: string
}

export const getSellerNavItems = ({
  analyticsFirstTab,
  counters,
  isMobile,
  locale,
  optionFlags,
  pathname,
}: GetSellerNavItemsArgs) =>
  compact<NavItem>([
    !!optionFlags.canUseSellPage && {
      'data-e2e': 'sell-nav-item',
      icon: 'basket-shopping',
      isActive:
        pathname.includes(`/${locale}${APPLI_URL.SELLER.MARKET.BASE}`) ||
        pathname.includes(`/${locale}${APPLI_URL.SELLER.PRODUCT.BASE}`) ||
        pathname.includes(`/${locale}${APPLI_URL.SELLER.PRESALE.BASE}`),
      title: gettext('Sell'),
      to: `/${locale}${APPLI_URL.SELLER.MARKET.LIVE}`,
    },
    optionFlags.canUseStockManagementModule && {
      'data-e2e': 'stock-nav-item',
      icon: 'boxes-stacked',
      isActive: pathname.includes(`/${locale}${APPLI_URL.SELLER.STOCK_MANAGEMENT}`),
      title: gettext('Stock'),
      to: `/${locale}${APPLI_URL.SELLER.STOCK_MANAGEMENT}`,
    },
    optionFlags.canUsePurchaseOrders && {
      'data-e2e': 'purchase-orders-nav-item',
      icon: 'truck-fast',
      isActive: pathname.includes(`/${locale}${APPLI_URL.SELLER.PURCHASE_ORDERS}`),
      notificationCount: counters.totalUnsyncOrdersCount,
      title: gettext('Purchase orders'),
      to: `/${locale}${APPLI_URL.SELLER.PURCHASE_ORDERS}`,
    },
    {
      'data-e2e': 'orders-nav-item',
      icon: 'truck-fast',
      isActive: pathname.includes(`/${locale}${APPLI_URL.SELLER.ORDERS.BASE}`),
      notificationCount: counters.totalTodayOrdersToPrepareCount + counters.totalPendingClaimsCount,
      title: optionFlags.canUsePurchaseOrders ? gettext('Sales order') : gettext('Orders'),
      to: `/${locale}${APPLI_URL.SELLER.ORDERS.BASE}`,
    },
    !isMobile &&
      analyticsFirstTab && {
        'data-e2e': 'analytics-nav-item',
        isActive: pathname.includes('/analytics/'),
        icon: 'chart-column',
        title: gettext('Analytics'),
        to: `/${locale}${APPLI_URL.SELLER.ANALYTICS}${analyticsFirstTab}/`,
      },
    !isMobile &&
      optionFlags.canUseInvoices && {
        'data-e2e': 'invoicing-nav-item',
        icon: 'receipt',
        isActive: pathname.includes(`/${locale}${APPLI_URL.SELLER.INVOICING.BASE}`),
        notificationCount: counters.waitingInvoicesCount,
        title: gettext('Invoices'),
        to: `/${locale}${APPLI_URL.SELLER.INVOICING.BASE}`,
      },
    optionFlags.canUseChat && {
      'data-e2e': 'chat-nav-item',
      icon: 'comments',
      notificationCount: counters.unreadChatMessagesCount,
      title: gettext('Chat'),
      to: `/${locale}${APPLI_URL.SELLER.CHAT}`,
    },
    !isMobile &&
      optionFlags.canUseMapping && {
        'data-e2e': 'mapping-item',
        icon: 'link',
        isActive: pathname.includes(`/${locale}${APPLI_URL.SELLER.MAPPING.BASE}`),
        notificationCount: counters.unmappedPurchasedProductsCount,
        title: gettext('Mapping'),
        to: `/${locale}/${APPLI_URL.SELLER.MAPPING.PURCHASES}`,
      },
    !isMobile &&
      optionFlags.canUseManagement && {
        'data-e2e': 'management-item',
        icon: 'sliders',
        isActive: pathname.includes(`/${locale}${APPLI_URL.SELLER.MANAGEMENT.BASE}`),
        title: gettext('Management'),
        to: `/${locale}${APPLI_URL.SELLER.MANAGEMENT.BASE}`,
      },
  ])

export interface SellerNavigationProps {
  seller?: SellerUser
}

const SellerNavigation = ({ seller }: SellerNavigationProps) => {
  const { locale } = useParams<LocaleParams>()
  const { pathname } = useLocation()
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const user = useUser()
  const sellerCompanyId = useSellerSellerCompanyId()

  const {
    totalPendingClaimsCount = 0,
    totalTodayOrdersToPrepareCount = 0,
    totalUnsyncOrdersCount,
    unmappedPurchasedProductsCount,
    waitingInvoicesCount,
  } = useUserCounts()

  const {
    optionFlagCanUseCatalog,
    optionFlagCanUseChat,
    optionFlagCanUseManagement,
    optionFlagCanUseMapping,
    optionFlagCanUsePurchaseOrders,
    optionFlagCanUseSalesInvoices,
    optionFlagCanUseStock,
  } = useOptionFlags()

  const { data: manageableEcosystemId } = useSellerSubscriptionsQuery({
    queryOptions: {
      select: sellerSubscriptions =>
        sellerSubscriptions.find(subscription => subscription.canManageEcosystem)?.marketplaceId,
    },
    queryParams: {
      companyId: sellerCompanyId,
    },
  })

  const { data: ecosystems = [] } = useMarketplacesQuery()
  const { data: dashboardPages } = usePaginatedDashboardsQuery({ queryParams: { limit: 1 } })

  const unreadChatMessagesCount = useUnreadChatMessagesCount()

  const manageableEcosystem = ecosystems.find(ecosystem => ecosystem.id === manageableEcosystemId)

  const analyticsFirstTab = dashboardPages?.pages?.[0].results?.[0]?.tabSlug
  const counters: Counters = {
    totalPendingClaimsCount,
    totalTodayOrdersToPrepareCount,
    totalUnsyncOrdersCount,
    unmappedPurchasedProductsCount,
    unreadChatMessagesCount,
    waitingInvoicesCount,
  }
  const optionFlags: OptionFlags = {
    canUseChat: optionFlagCanUseChat,
    canUseInvoices: optionFlagCanUseSalesInvoices,
    canUseManagement: optionFlagCanUseManagement,
    canUseMapping: optionFlagCanUseMapping,
    canUsePurchaseOrders: optionFlagCanUsePurchaseOrders,
    canUseSellPage: optionFlagCanUseCatalog,
    canUseStockManagementModule: optionFlagCanUseStock,
  }

  const navItems = getSellerNavItems({
    analyticsFirstTab,
    counters,
    isMobile,
    locale,
    optionFlags,
    pathname,
  })

  return user?.releaseToggleNewMenu ? (
    <MainMenu />
  ) : isMobile ? (
    <>
      <SellerMobileHeader
        logoSrc={(manageableEcosystem && manageableEcosystem.mobileLogo) || klarysLogoWhiteSmall}
      />
      <TabBar
        items={navItems}
        accountTabBarItem={<SellerAccountMenuItem analyticsFirstTab={analyticsFirstTab} />}
      />
    </>
  ) : (
    <StyledSideBar
      data-e2e="side-bar"
      header={
        <Logo
          alt="ecosystem-logo"
          isSmall={isTablet}
          src={
            (manageableEcosystem && manageableEcosystem.desktopLogoLegacy) || klarysLogoWhiteSmall
          }
        />
      }
      navItems={navItems}
      commandMenu={
        <>
          <SellerAccountMenuItem compact={isTablet} key="account-menu-item" />
          {isDesktop && <UserInfo fullName={seller?.fullName} />}
          <AppEnvironment isTablet={isTablet} />
        </>
      }
      footer={<HelpMenu />}
      compact={isTablet}
    />
  )
}

export default SellerNavigation
