import React, { Suspense, lazy, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom'

import { AppWrapper, PageContent } from '@procsea/design-system'

import PageLoader from 'src/components/base/loader/PageLoader'
import NotFound from 'src/components/base/notFound/NotFound'
import ErrorBoundary from 'src/components/presentationals/shared/errors/ErrorBoundary'
import { APPLI_URL } from 'src/constants/constants'
import { useSeller, useUser } from 'src/contexts/userContext'
import { useOptionFlags } from 'src/hooks'
import useChat, { createConversationClient } from 'src/hooks/useChat'
import useOrganisationPusherSubscriptions from 'src/hooks/useOrganisationPusherSubscription'
import { useChatTokenQuery } from 'src/queries/shared'
import { LocaleParams, SellerUserWithCurrency } from 'src/types'

import { useChatActions, useChatConversationClient } from '../chat/store/useChatStore'
import SellerNavigation from './navigation/SellerNavigation'
import { usePusherSubscriptions } from './shared/hooks'
import useInitializeSellerDashboard from './shared/hooks/useInitializeSellerDashboard'

const AsyncSellerMarketRoutes = lazy(
  () => import('src/components/presentationals/seller-dashboard/market/SellerMarketRoutes')
)

const AsyncSellerStockManagement = lazy(
  () =>
    import('src/components/presentationals/seller-dashboard/stock-management/SellerStockManagement')
)

const AsyncSellerPurchaseMapping = lazy(
  () => import('src/components/presentationals/mapping/purchase-mapping/PurchaseMapping')
)

const AsyncManagement = lazy(() => import('src/components/presentationals/management/Management'))

const AsyncAnalytics = lazy(() => import('./analytics/Analytics'))

const AsyncChat = lazy(() => import('src/components/presentationals/chat/Chat'))

const AsyncInvoicing = lazy(
  () => import('src/components/presentationals/seller-dashboard/invoicing/Invoicing')
)
const AsyncPurchaseOrdersRoutes = lazy(
  () => import('src/components/presentationals/purchase-orders/PurchaseOrders.routes')
)

const AsyncOrdering = lazy(
  () => import('src/components/presentationals/seller-dashboard/ordering/Ordering')
)

const AsyncSellerCustomersRoutes = lazy(
  () => import('src/components/presentationals/seller-dashboard/customers/Routes')
)

const AsyncMyCompany = lazy(
  () => import('src/components/presentationals/seller-dashboard/my-company/MyCompany')
)

const AsyncCustomerCategories = lazy(
  () =>
    import('src/components/presentationals/seller-dashboard/customer-categories/CustomerCategories')
)

const AsyncCustomerCategory = lazy(
  () => import('src/components/presentationals/seller-dashboard/customer-category/CustomerCategory')
)

export interface SellerDashboardProps {
  seller: SellerUserWithCurrency
}

const SellerDashboard = () => {
  const seller = useSeller()
  const user = useUser()
  const {
    optionFlagCanUseCatalog,
    optionFlagCanUseManagement,
    optionFlagCanUseMapping,
    optionFlagCanUsePurchaseOrders,
  } = useOptionFlags()
  const { data: chatToken } = useChatTokenQuery({ enabled: !!seller?.canUseChat })
  const { setConversationClient } = useChatActions()
  const conversationClient = useChatConversationClient()
  const { search } = useLocation()
  const { locale } = useParams<LocaleParams>()

  usePusherSubscriptions()
  useOrganisationPusherSubscriptions()
  useChat()
  const { isReady } = useInitializeSellerDashboard()

  const getDefaultPageForRedirection = () => {
    if (optionFlagCanUsePurchaseOrders) {
      return `/${locale}${APPLI_URL.SELLER.PURCHASE_ORDERS}`
    }

    if (optionFlagCanUseCatalog) {
      return `/${locale}${APPLI_URL.SELLER.MARKET.LIVE}`
    }

    return `/${locale}${APPLI_URL.SELLER.ORDERS.BASE}`
  }

  useEffect(() => {
    if (seller?.canUseChat && !conversationClient && chatToken?.token) {
      const client = createConversationClient(chatToken.token)
      setConversationClient(client)
    }
  }, [seller?.canUseChat, chatToken?.token])

  return isReady ? (
    <AppWrapper>
      <SellerNavigation seller={seller} />
      <PageContent isLegacy={!user?.releaseToggleNewMenu}>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {optionFlagCanUseCatalog && (
              <Route
                path={[
                  `/:locale${APPLI_URL.SELLER.MARKET.BASE}`,
                  `/:locale${APPLI_URL.SELLER.PRODUCT.BASE}:action/:id?`,
                ]}
              >
                <AsyncSellerMarketRoutes />
              </Route>
            )}

            {seller?.canUseStockManagementModule && (
              <Route path={`/:locale${APPLI_URL.SELLER.STOCK_MANAGEMENT}`}>
                <AsyncSellerStockManagement />
              </Route>
            )}

            {optionFlagCanUseMapping && (
              <Route
                path={[
                  `/:locale${APPLI_URL.SELLER.MAPPING.BASE}`,
                  `/:locale${APPLI_URL.SELLER.MAPPING.PURCHASES}`,
                ]}
              >
                <AsyncSellerPurchaseMapping />
              </Route>
            )}

            {optionFlagCanUsePurchaseOrders && (
              <Route path={`/:locale${APPLI_URL.SELLER.PURCHASE_ORDERS}`}>
                <ErrorBoundary>
                  <AsyncPurchaseOrdersRoutes />
                </ErrorBoundary>
              </Route>
            )}

            {optionFlagCanUseManagement && (
              <Route path={`/:locale${APPLI_URL.SELLER.MANAGEMENT.BASE}`}>
                <ErrorBoundary>
                  <AsyncManagement />
                </ErrorBoundary>
              </Route>
            )}

            <Route path={[`/:locale/:userType(seller)/orders`]}>
              <ErrorBoundary>
                <AsyncOrdering />
              </ErrorBoundary>
            </Route>

            <Route exact path="/:locale/:userType/analytics/:dashboardHash?">
              <ErrorBoundary>
                <AsyncAnalytics />
              </ErrorBoundary>
            </Route>

            {seller?.canUseChat && (
              <Route path="/:locale/:userType/chat">
                <ErrorBoundary>
                  <AsyncChat />
                </ErrorBoundary>
              </Route>
            )}

            {seller?.optionFlagDisplayInvoicesTab && (
              <Route path="/:locale/:userType/invoicing">
                <ErrorBoundary>
                  <AsyncInvoicing />
                </ErrorBoundary>
              </Route>
            )}

            <Route path="/:locale/:userType(seller)/my-company">
              <ErrorBoundary>
                <AsyncMyCompany />
              </ErrorBoundary>
            </Route>

            <Route path="/:locale/:userType(seller)/customer-categories">
              <ErrorBoundary>
                <AsyncCustomerCategories />
              </ErrorBoundary>
            </Route>

            <Route path="/:locale/:userType(seller)/customer-category/:customerCategoryId?">
              <ErrorBoundary>
                <AsyncCustomerCategory />
              </ErrorBoundary>
            </Route>

            <Route path={`/:locale${APPLI_URL.SELLER.CUSTOMERS.BASE}`}>
              <ErrorBoundary>
                <AsyncSellerCustomersRoutes />
              </ErrorBoundary>
            </Route>

            <Route exact path="/:locale/:userType(seller)/">
              <Redirect
                to={{
                  pathname: getDefaultPageForRedirection(),
                  search,
                }}
              />
            </Route>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </PageContent>
    </AppWrapper>
  ) : null
}

export default SellerDashboard
